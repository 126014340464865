.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  padding: 0;
}

.h1 {
  font-family: KanitBold;
  font-weight: 800;
  font-size: 96px;
  line-height: 78.5%;
  letter-spacing: -0.006em;
}
.h2 {
  font-family: KanitBold;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 104.5%;
  letter-spacing: -0.015em;
}
.h3 {
  font-family: KanitBold;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 98%;
  letter-spacing: 0.005em;
}
.h4 {
  font-family: KanitBold;
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 110%;
}
.h5 {
  font-family: KanitBold;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 125%;
  letter-spacing: 0.015em;
}
.h6 {
  font-family: KanitBold;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: 0.0075em;
}

@media screen and (max-width: 768px) {
  .h1 {
    font-size: 48px;
  }
  .h2 {
    font-size: 32px;
  }
  .h3 {
    font-size: 24px;
  }
  .h4 {
    font-size: 20px;
  }
  .h5 {
    font-size: 16px;
  }
  .h6 {
    font-size: 14px;
  }
}
