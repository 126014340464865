@font-face {
  font-family: KanitBold;
  src: local('KanitBold'),
    url('./assets/fonts/Kanit/Kanit-Bold.ttf') format('truetype');
}

@font-face {
  font-family: KanitBoldItalic;
  src: local('KanitBoldItalic'),
    url('./assets/fonts/Kanit/Kanit-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: KanitExtraBold;
  src: local('KanitExtraBold'),
    url('./assets/fonts/Kanit/Kanit-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: KanitExtraBoldItalic;
  src: local('KanitExtraBoldItalic'),
    url('./assets/fonts/Kanit/Kanit-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: KanitExtraLight;
  src: local('KanitExtraLight'),
    url('./assets/fonts/Kanit/Kanit-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: KanitExtraLightItalic;
  src: local('KanitExtraLightItalic'),
    url('./assets/fonts/Kanit/Kanit-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: KanitItalic;
  src: local('KanitItalic'),
    url('./assets/fonts/Kanit/Kanit-Italic.ttf') format('truetype');
}

@font-face {
  font-family: KanitLight;
  src: local('KanitLight'),
    url('./assets/fonts/Kanit/Kanit-Light.ttf') format('truetype');
}

@font-face {
  font-family: KanitLightItalic;
  src: local('KanitLightItalic'),
    url('./assets/fonts/Kanit/Kanit-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: KanitMedium;
  src: local('KanitMedium'),
    url('./assets/fonts/Kanit/Kanit-Medium.ttf') format('truetype');
}

@font-face {
  font-family: KanitMediumItalic;
  src: local('KanitMediumItalic'),
    url('./assets/fonts/Kanit/Kanit-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: KanitRegular;
  src: local('KanitRegular'),
    url('./assets/fonts/Kanit/Kanit-Regular.ttf') format('truetype');
}

@font-face {
  font-family: KanitSemiBold;
  src: local('KanitSemiBold'),
    url('./assets/fonts/Kanit/Kanit-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: KanitSemiBoldItalic;
  src: local('KanitSemiBoldItalic'),
    url('./assets/fonts/Kanit/Kanit-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: KanitThin;
  src: local('KanitThin'),
    url('./assets/fonts/Kanit/Kanit-Thin.ttf') format('truetype');
}

@font-face {
  font-family: KanitThinItalic;
  src: local('Kanit'),
    url('./assets/fonts/Kanit/Kanit-ThinItalic.ttf') format('truetype');
}

* {
  font-family: KanitRegular;
}
